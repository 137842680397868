import { Container, Background, Content } from "../modal/ModalBG";
import ModalHeader from "./ModalHeader";
import styled from "styled-components";
import tw from "twin.macro";

const InfoContent = styled(Content)`
  ${tw`
        text-white
        self-center
        flex
        flex-col
        h-full
        mx-3
    `}
  height:fit-content;
`;

const MessageContainer = styled.div`
  ${tw`
        w-full
        max-w-5xl
        self-center
        overflow-auto
        pt-5
    `}
`;

const Message = styled.div`
  ${tw`
        w-full
        p-5
        pt-0
    `}
`;

const DefaultBG = tw`
    bg-primary
`;

interface Prop {
  title: string;
  info: React.ReactNode | React.ReactNode[];
  headerStyle?: React.CSSProperties;
  modalStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  state: boolean;
  close: Function;
}

function Modal(prop: Prop) {
  return (
    <Container className={prop.state ? "active" : ""}>
      <Background
        onClick={() => {
          prop.close();
        }}
      />
      <InfoContent style={prop.modalStyle ? prop.modalStyle : DefaultBG}>
        <ModalHeader
          title={prop.title}
          close={prop.close}
          headerStyle={prop.headerStyle}
          textStyle={prop.textStyle}
        />
        <MessageContainer>
          <Message>{prop.info}</Message>
        </MessageContainer>
      </InfoContent>
    </Container>
  );
}

export default Modal;
