interface Faq{
    title:string,
    desc:string,
}

const Faqs:Faq[] = [
    {
        title:"What is the Guava Girls Fruit Stand?",
        desc:"To thank our holders for supporting our vision, in addition to owning a beautiful and unique generative art piece created by Arty Guava, we want to reward you with additional perks. Our Fruit Stand is a loyalty program that allows you to earn $GUAVA, with the flexibility to redeem it for rewards of your choice."
    },
    {
        title:"Why have I stopped earning $GUAVA?",
        desc:"$GUAVA deliveries have been paused for all Guava Girls holders starting October 20, 2023. Please join our Discord community for updates."
    },
    {
        title:"Can I still redeem $GUAVA for rewards?",
        desc:"Yes, the Marketplace will remain open for redeeming rewards. Please connect your wallet to view your $GUAVA balance. You will have earned $GUAVA for each Guava Girls you own, starting from the first day you connected to our Fruit Stand until October 19, 2023."
    },
    {
        title:"Where can I learn more about the Fruit Stand and $GUAVA?",
        desc:"Check out the article on our wiki: <a className='underline text-orange font-bold' href='http://wiki.guavagirlsnft.com/' target='blank'>http://wiki.guavagirlsnft.com/</a>"
    },
]

export { Faqs };
export type { Faq };
