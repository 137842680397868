import * as AT from "../actions/actionTypes"

let init_state = {
    isActive:false,
};

export default function modalReducer(state = init_state, action:any){
    switch (action.type){
        case AT.SET_MODAL:
            return {
                isActive:action.isActive
            };
            
        default:
            return state;
    }
}
