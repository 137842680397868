
import * as AT from "../actions/actionTypes";

let init_state = {
    activities:[],
};

export default function activityReducer(state = init_state, action:any){
    switch (action.type){
        case AT.ADD_ACTIVITIES:
            return {
                ...state,
                activities:action.activities
            }

        default:
            return state;
    }
}
