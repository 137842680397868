import tw from "twin.macro";

const Wrapper = tw`
    w-full
    h-full
    flex 
    flex-col 
    min-h-screen
    items-center

`
const Section = tw`
    w-full  
    pb-8
    flex
    flex-col
    items-center
    justify-center
    pl-3
    pr-3
    mt-5
    mb-5
`
const Content = tw`
    max-w-5xl
    w-full
    md:px-5
`

export { Wrapper, Section, Content }