import { addProducts, setCurrProduct } from "../../redux/actions/market";
import store from "../../redux/store";
import { SUCCESS } from "../status";
import axios from "axios";
import { Product } from "../Interface/product";
import { setTotalBalance } from "../../redux/actions/balance";

// Load the User Guava Balances
async function SetupMarket() {
  let url = `${process.env.REACT_APP_BACKEND_URL}/products`;
  let products: Product[] = [];

  await axios
    .get(url)
    .then((res) => {
      if (res.data.status === SUCCESS) {
        products = res.data.data.products;
      }
    })
    .catch((err) => {
      // Error 404
      throw new Error("Trouble Getting the Products");
    });

  store.dispatch(addProducts(products));
}

async function getProduct(id: number) {
  let url = `${process.env.REACT_APP_BACKEND_URL}/products/${id}`;

  await axios
    .get(url)
    .then((res) => {
      if (res.data.data.product !== null) {
        let products: Product = res.data.data.product;
        store.dispatch(setCurrProduct(products));
      } else {
        console.log("nothing");
      }
    })
    .catch((err) => {
      // Error 404
      throw new Error("Trouble Getting the Products");
    });
}

async function purchaseProduct(id: number, name: string, email: string) {
  const auth = store.getState().auth;

  let url = `${process.env.REACT_APP_BACKEND_URL}/purchases/product/${id}`;
  let header = { Authorization: "Bearer " + auth.token };
  let body = {
    userId: `${auth.userId}`,
    recipientName: `${name}`,
    recipientEmail: `${email}`,
  };

  await axios
    .post(url, body, { headers: header })
    .then((res) => {
      if (res.data.data.purchase.state === "complete") {
        const removedBalance = res.data.data.purchase.price;
        const currBalance = store.getState().balance.totalBalance;
        store.dispatch(setTotalBalance(currBalance - removedBalance));
      }
    })
    .catch((err) => {
      // Error 404
      if (err.response.data.message === "purchase.insufficient_balance") {
        throw new Error("Insufficient Guava Balance");
      } else if (
        err.response.data.message === "purchase.insufficient_quantity"
      ) {
        throw new Error("Product Sold Out");
      } else {
        throw new Error("Product No Longer Available to Purchase");
      }
    });
}

export { SetupMarket, getProduct, purchaseProduct };
