import * as Actions from "../../redux/actions/balance";
import store from "../../redux/store";
import { SUCCESS } from "../status";
import axios from "axios";
import { SnapshotDefault } from "../Interface/snapshot";

async function setupEarnings() {
  const auth = store.getState().auth;
  let url = `${process.env.REACT_APP_BACKEND_URL}/snapshots/details/${auth.address}`;
  await axios
    .get(url, { headers: { Authorization: "Bearer " + auth.token } })
    .then(SetEarnings)
    .catch((err) => {
      // Error 404
      throw new Error("Trouble Getting Your Daily Earning Snapshot");
    });
}

function SetEarnings(response: any) {
  if (response.data.status === SUCCESS) {
    let snapshot = SnapshotDefault;
    let snapEarned = 0;

    if (response.data.data.snapshot == null) {
      return;
    }

    let data = response.data.data.snapshot.earningDetails;
    let date = response.data.data.snapshot.createdAt;

    if (date != null) {
      snapshot.date = date;
    }

    if (data != null) {
      snapshot.classic.value = data.classic.value;
      snapshot.classic.count = data.classic.count;

      snapshot.fruity.value = data.fruity.value;
      snapshot.fruity.count = data.fruity.count;

      snapshot.legendary.value = data.legendary.value;
      snapshot.legendary.count = data.legendary.count;

      snapEarned += snapshot.classic.value * snapshot.classic.count;
      snapEarned += snapshot.fruity.value * snapshot.fruity.count;
      snapEarned += snapshot.legendary.value * snapshot.legendary.count;

      // Set the snapshot array
      snapshot.snapEarning = snapEarned;
      store.dispatch(Actions.setSnapshot(snapshot));
    }
  }
}

export { setupEarnings };
