import * as AT from "./actionTypes"
import {Product} from "../../utilities/Interface/product";

const addProducts = (products:Product[]) => ({
    type:AT.ADD_PRODUCTS,
    products:products
})


const toggleProductModal = (index:number, isSelected:boolean) => ({
    type:AT.TOGGLE_PRODUCT_MODAL,
    isSelected:isSelected,
    index:index
})

const setCurrProduct = (curr_product:Product) => ({
    type:AT.SET_CURR_PRODUCT,
    curr_product:curr_product,
})

const setIsLoading = (isLoading:boolean) => ({
    type:AT.SET_IS_LOADING,
    isLoading:isLoading,
})

export {addProducts, toggleProductModal, setCurrProduct, setIsLoading};

