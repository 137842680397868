interface SnapshotEarning {
  rarity: string;
  value: number;
  count: number;
}

interface Snapshot {
  date: string | null;
  snapEarning: number;
  classic: SnapshotEarning;
  fruity: SnapshotEarning;
  legendary: SnapshotEarning;
}

const SnapshotDefault: Snapshot = {
  date: null,
  snapEarning: 0,
  classic: {
    rarity: "Classic",
    value: 10,
    count: 0,
  },
  fruity: {
    rarity: "Fruity",
    value: 15,
    count: 0,
  },
  legendary: {
    rarity: "Legendary",
    value: 30,
    count: 0,
  },
};

const db_rarities = {
  classic: "baseEarnings",
  fruity: "fruityEarnings",
  legendary: "legendaryEarnings",
};

export { SnapshotDefault, db_rarities };
export type { SnapshotEarning, Snapshot };
