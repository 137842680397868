import tw, { TwStyle } from "twin.macro";

function getHeaderBG(pt: number): TwStyle {
  if (pt === 1) return tw`bg-product_guava `;
  else if (pt === 2) return tw`bg-product_goodies `;
  else if (pt === 3) return tw`bg-product_nft `;
  else return tw`bg-bg_bot`;
}

function getHeaderText(pt: number): TwStyle {
  if (pt === 1) return tw`text-primary `;
  else if (pt === 2) return tw`text-primary`;
  else if (pt === 3) return tw`text-white `;
  else return tw`text-white`;
}

export { getHeaderBG, getHeaderText };
