
import { Product } from "../../utilities/Interface/product";
import * as AT from "../actions/actionTypes";

const prodArray:Product[] = [];
let init_state = {
    products:prodArray,
    curr_product:null,
    isLoading:false,
};

export default function marketReducer(state = init_state, action:any){
    switch (action.type){
        case AT.ADD_PRODUCTS:
            return {
                ...state,
                products:action.products
            }
        case AT.TOGGLE_PRODUCT_MODAL:
            return {
                ...state,
                products:state.products.map(
                    (item, i) => i === action.index ?
                        { ...item, isSelected:action.isSelected}:
                        { ...item }
                )
            }
        case AT.SET_CURR_PRODUCT:
            return {
                ...state,
                curr_product:action.curr_product
            }
            
        case AT.SET_IS_LOADING:
            return {
                ...state,
                isLoading:action.isLoading
            }


        default:
            return state;
    }
}
