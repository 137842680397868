// Purchase Types

// 1: Buy, 2: Raffle, 3:Auction
interface PurchaseType {
  id: number;
  name: string;
}

// interface PurchaseTypes {
//   prchaseTypeId: number;
//   prchaseType: PurchaseType;
// }

// Product Types

// 1: Guava, 2: Goodies, 3:NFT
interface ProductType {
  id: number;
  name: string;
}

interface ProductTypes {
  productTypeId: number;
  productType: ProductType;
}

const PurchaseType_Purchase: PurchaseType = {
  id: 1,
  name: "Purchase",
};

interface Product {
  id: number;
  createdAt: string;
  available: boolean;
  price: number;
  title: string;
  description: string;
  quantity: number;
  startDate: string;
  endDate: string;
  images: string[];
  purchaseTypes: PurchaseType;
  productTypes: ProductTypes[];
  isSelected: boolean;
  soldOut: boolean;
  learnMoreLink: string | null;
}

interface Purchase {
  putchaseType: PurchaseType;
  name: string;
  email: string;
}

const defaultProductType = { productType: { id: -1, name: "Uncategorized" } };

function isProduct(object: any): object is Product {
  return "productTypes" in object;
}

export type { Product, Purchase, PurchaseType, ProductType };
export { PurchaseType_Purchase, isProduct, defaultProductType };
