import Banner from "../../components/balance/Banner";
import Earnings from "./Earnings";
import Layout from "../../components/layout/Layout";
import * as Container from "../../components/containers/Container";
import Activities from "./Activities";
import { Space } from "../../components/filler/Filler";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";

const Section = styled.div`
  ${Container.Section}
`

const Content = styled.div`
  ${Container.Content}
  ${tw`
    text-primary
  `}
`

function Dashboard() {
  const auth = useSelector((state:any)=>state.auth);

  return (
    <Layout>
        <Banner/>
        <Section>
          <Content>
            <Earnings/>
          </Content>
          <Space/>
          <Content>
            { auth.isConnected ? <Activities/> : <></> }
          </Content>
        </Section>
    </Layout>
  );
}

export default Dashboard;