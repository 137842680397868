import * as BalanceActions from "../../redux/actions/balance";
import store from "../../redux/store";
import { SUCCESS } from "../status";
import axios from "axios";

// Load the User Guava Balances
export async function SetupBalance() {
  let auth = store.getState().auth;

  let url = `${process.env.REACT_APP_BACKEND_URL}/users/id/${auth.userId}`;
  await axios
    .get(url, { headers: { Authorization: "Bearer " + auth.token } })
    .then((res) => {
      if (res.data.status === SUCCESS) {
        let data = res.data.data.user;
        store.dispatch(BalanceActions.setTotalBalance(data.balance));
      }
    })
    .catch((err) => {
      // Error 404
      throw new Error("Trouble Getting Your Balance");
    });
}
