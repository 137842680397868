// import { useSelector } from "react-redux";
import { Body, Title } from "../../components/text/Text";
// import { Space } from "../../components/filler/Filler";
// import TableSnapshot from "../../components/balance/TableSnapshot";
// import { Snapshot, SnapshotEarning } from "../../utilities/Interface/snapshot";
// import { getFormattedDate } from  "../../utilities/Helper/formatDate"

function Earnings() {
    // const auth = useSelector((state:any)=>state.auth);
    // const balance = useSelector((state:any)=>state.balance);
    // const snap:Snapshot = balance.snapshot;

    // let Header:string[] = ["Guava Girl", "Owned", "$GUAVA"];
    // let Snapshot:SnapshotEarning[] = [  
    //     snap.classic,
    //     snap.fruity,
    //     snap.legendary
    // ];
    // let Earned:number = balance.snapshot.snapEarning;
    return (
          <>
              <Title>DAILY $GUAVA EARNINGS</Title>
              <Body>$GUAVA deliveries are on hold until further notice. Please join our Discord community for updates.</Body>
          </>
        // <>
        //     <Title>DAILY $GUAVA EARNINGS</Title>
        //     <Body>{auth.isConnected && (balance.snapshot.date!=null)?`Your snapshot for ${getFormattedDate(balance.snapshot.date)}`:""}</Body>
        //     <TableSnapshot header={Header} data={Snapshot} state={auth.isConnected} total={Earned}/>
        //     <Space/>
        //     <Note><b>Note:</b> Balance and # owned is 0 the first day you connect your wallet, and will be updated once the next daily snapshot is taken, around 1am PST.</Note>
        // </>
    );
}

export default Earnings;