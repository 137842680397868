import SocialsContainer from "../socials/SocialsContainer";
import styled from "styled-components";
import tw from "twin.macro";

const Border = tw`
    bg-repeat-x
    bg-top
    background-size[100px]
`
const Background = styled.div`
  ${tw`
    bg-primary
    w-full
    flex
    justify-center
  `}
`

const FooterBase = styled.div`
    ${Border}
    ${tw`
        pt-16
        flex
        justify-center
        items-center
        w-full
    `}
    background-image: url("/img/borders/green-header-bg-2.png");
`

const FooterContent = styled.div`
  ${tw`
    max-w-5xl
    w-full
    md:pl-5
    md:pr-5

    bg-primary
    flex
    flex-col
    items-center
    h-full
    md:h-24
    md:flex-row
    md:justify-between
    md:items-start
  `}
`

const FooterContainer = styled.div`
  ${tw`
    flex
    flex-col
    items-center
    pb-5
    md:p-0
    md:items-end
  `}
`

const Logo = styled.img`
  ${tw`
    h-12
    mb-2
  `}
`

const Text = styled.div`
  ${tw`
    text-neon_green
    text-center
  `}
`

function Footer() {
  let year = new Date().getFullYear();
  return (
    <FooterBase>
      <Background >
        <FooterContent >
          <Logo src="/img/logo/logo-neon-green.png"/>
          <FooterContainer>
              <SocialsContainer/>
              <Text>(c) {year} Guava Girls NFT. All right reserved.</Text>
          </FooterContainer>
        </FooterContent>
      </Background>
    </FooterBase>     
  );
}

export default Footer;