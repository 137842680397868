import Layout from "../../components/layout/Layout";
import * as Container from "../../components/containers/Container";
import { Filler, Space } from "../../components/filler/Filler";
import tw from "twin.macro";
import styled from "styled-components";
import { Title } from "../../components/text/Text";
import { SetupMarket } from "../../utilities/API/market";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ProductGrid from "../../components/product/Grid";
import { CoinSrc } from "../../utilities/Image/images";

const Icon = styled.img`
  ${tw`
    w-32
  `}
`;

const Message = styled.div`
  ${tw`
    text-primary
    font-title
    text-[5em]
    pt-5
    text-center
    line-height[1em]
    md:text-[8em]
  `}
`;

const CurrentBalance = styled.div`
  ${tw`
    flex
    items-center
  `}
`;
const Balance = styled.div`
  ${tw`
    text-[1.8em]
    font-title
  `}
`;

const Coin = styled.img`
  ${tw`
    w-10
    h-10
  `}
`;

const SoonContent = styled.div`
  ${Container.Content}
  ${tw`
    flex
    flex-col
    justify-center
    items-center
    gap-5
    sm:flex-row
  `}
`;
const Header = styled.div`
  ${tw`
        w-full
        flex
    `}
`;

const IconActive = styled.img`
  ${tw`
    w-12
    h-12
  `}
`;

const Section = styled.div`
  ${Container.Section}
  ${tw`
    text-primary
  `}
`;

const Content = styled.div`
  ${Container.Content}
`;

function Market() {
  let isMarketActive = true;
  const auth = useSelector((state: any) => state.auth);
  const balance = useSelector((state: any) => state.balance);
  const marketProducts = useSelector((state: any) => state.market);
  const productList = marketProducts.products;

  useEffect(() => {
    SetupMarket();
  }, []);

  return (
    <Layout>
      {isMarketActive ? (
        <Section>
          <Content>
            <Header>
              <IconActive src="./img/logo/Guava_Girls-3.png"></IconActive>
              <Title> Market</Title>
              <Filler />
              {auth.isConnected ? (
                <CurrentBalance>
                  <Balance>{balance.totalBalance}</Balance>
                  <Coin src={CoinSrc}></Coin>
                </CurrentBalance>
              ) : (
                <></>
              )}
            </Header>
            <Space />
            {productList.length > 0 ? (
              <ProductGrid products={productList} />
            ) : (
              <Message>No Available Products</Message>
            )}
          </Content>
        </Section>
      ) : (
        <>
          <Filler />
          <SoonContent>
            <Icon src="./img/logo/Guava_Girls-3.png"></Icon>
            <Message data-testid="market">COMING SOON!</Message>
          </SoonContent>
          <Filler />
        </>
      )}
    </Layout>
  );
}

export default Market;
