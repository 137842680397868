import tw from "twin.macro";

const Filler = tw.div`
    flex-grow
`

const Space = tw.div`
  h-5
`

export { Filler, Space }