import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import * as TableComponent from "../table/Table"
import {Activity} from "../../utilities/Interface/activity";
import { getFormattedDate } from "../../utilities/Helper/formatDate";
import { CoinSrc } from "../../utilities/Image/images";

const ValueCoin = styled.div`
    ${tw`
        flex
    `}  
`

const Coin = styled.img`
    ${tw`
        w-6
        h-6
    `}   
`

const CoinImg = <Coin src={CoinSrc}/>


interface Props {
    header:string[],
    data:Activity[]
}

function ActivityTable(prop:Props){
    const headers:string[] = [...prop.header];
    const data:JSX.Element[][] = [];
    
    const createData = (data:Activity) => {
        const date = <>{data.createdAt ? getFormattedDate(data.createdAt): "N/A"}</>
        const activity = <>{data.title}</>
        const earned = data.earnings ? <ValueCoin>{data.earnings} {CoinImg}</ValueCoin>: <>N/A</>
        return [date, activity, earned];
    }

    if (prop.data.length > 0){
        prop.data.forEach((d:Activity) => {
            data.push(createData(d));
        })
    } else{
        const empty = <></>;
        const noActivity = <>No Activity History</>;
        data.push([empty, noActivity, empty]);
    }

    return (
        <TableComponent.Table
            rowHeader={headers}
            data={data}
        />
    )
}

export default ActivityTable;