import Web3 from "web3";
import { setNotificationMessage } from "../../redux/actions/notification";
import { setUser, clearUser, setStorageData } from "./HandleUserData";
import { SUCCESS } from "../status";
import { setupAccount } from "../initSetup";
import axios from "axios";
import store from "../../redux/store";

class AuthenticateUser {
  private web3: Web3 | undefined;

  constructor() {
    this.web3 = undefined;
  }

  private handleSignMessage = async ({
    address,
    nonce,
  }: {
    address: string;
    nonce: string;
  }) => {
    try {
      const signature = await this.web3!.eth.personal.sign(
        // TODO: make format a constant, has to be inline with BE
        `Connect your wallet to the Guava Girls Fruit Stand to verify your NFTs and redeem rewards.\n\nId: ${nonce}`,
        address,
        ""
      );
      return { address, signature };
    } catch (err) {
      throw new Error("Please Sign in");
    }
  };

  private saveUser = (userInfo: any, address: string) => {
    if (userInfo.data.status === SUCCESS) {
      let data = userInfo.data.data;
      setStorageData(data.token, data.userId);
      setUser(address, data.token, data.userId);
    }
  };

  private handleAuthenticate = ({
    address,
    signature,
  }: {
    address: string;
    signature: string;
  }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/auth/signature`;
    try {
      return axios.post(url, { address: address, signature: signature });
    } catch {
      throw new Error("Authentication Error");
    }
  };

  private handleSignup = (address: string) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users`;

    return axios.post(url, { address: address }).then((body) => {
      try {
        return body.data.data.user.wallet;
      } catch {
        throw new Error("Signup Error");
      }
    });
  };

  private handleCheckAccount = async (address: string) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users/${address}`;
    try {
      return axios.get(url).then((body) => {
        let user = body.data.data.user;
        if (user) {
          return user.wallet;
        } else {
          return this.handleSignup(address);
        }
      });
    } catch {
      throw new Error("Error");
    }
  };

  public onConnect = async () => {
    const reload = () => window.location.reload();
    try {
      // check if MetaMask is installed
      if (!(window as any).ethereum) {
        // Check if on Mobile
        try {
          if (isMobileDevice()) {
            const dappUrl = process.env.REACT_APP_FRONTEND_URL;
            const metamaskAppDeepLink =
              "https://metamask.app.link/dapp/" + dappUrl;
            (window as any).location = metamaskAppDeepLink;
          }
        } catch {
          throw new Error("Please install MetaMask first.");
        }
      }

      if (!this.web3) {
        try {
          await (window as any).ethereum.request({
            method: "eth_requestAccounts",
          });
          this.web3 = new Web3((window as any).ethereum);
        } catch (error) {
          throw new Error("You need to allow MetaMask.");
        }
      }

      const account = await this.web3!.eth.getAccounts();
      if (account.length < 1) {
        throw new Error("Please activate MetaMask first");
      }

      const address = account[0];

      this.handleCheckAccount(address)
        .then(this.handleSignMessage) // popup MetaMask confirmation modal to sign message
        .then(this.handleAuthenticate) // send signature to backend on the /auth route
        .then((token) => this.saveUser(token, address)) // pass token back to parent component (to save it in localStorage)
        .then(setupAccount)
        .then(reload)
        .catch((err: Error) => {
          clearUser();
          store.dispatch(setNotificationMessage(err.message, true));
        });
    } catch (err: any) {
      store.dispatch(setNotificationMessage(err.message, true));
    }
  };
}

function isMobileDevice() {
  return "ontouchstart" in window || "onmsgesturechange" in window;
}

export default AuthenticateUser;
