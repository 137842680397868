import { SetupActivities } from "./API/activities";
import { SetupBalance } from "./API/balance";
import { setupEarnings } from "./API/earnings";
import { setupRarity } from "./API/rarities";
import { setNotificationMessage } from "../redux/actions/notification";
import store from "../redux/store";
import AuthInit from "./Auth/AuthInit";

// Initial setup to load the rarities
function initSetup() {
  setupRarity()
    .then(AuthInit) // Check if Logged in
    .catch((err: Error) => {
      store.dispatch(setNotificationMessage(err.message, true));
    });
}

// Call user API and put information in redux
function setupAccount() {
  SetupBalance()
    .then(setupEarnings)
    .then(SetupActivities)
    .catch((err: Error) => {
      console.log(err.message);
    });
}

export { initSetup, setupAccount };
