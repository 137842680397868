import * as AT from "../actions/actionTypes"

let init_state = {
    isConnected:false,
    token:null,
    address:null,
    userId:null,
};

function authReducer(state = init_state, action:any){
    switch (action.type){
        case AT.TOGGLE_CONNECTED:
            return {
                ...state,
                isConnected:!state.isConnected,
            }
        case AT.SET_CONNECTED:
            return {
                ...state,
                isConnected:action.isConnected
            };

        case AT.SET_TOKEN:
            return  {
                ...state,
                token: action.token
            };

        case AT.DEL_TOKEN:
            return  {
                ...state,
                token:null,
            };

        case AT.SET_ADDRESS:
            return  {
                ...state,
                address:action.address,
            };

        case AT.DEL_USER_ID:
            return  {
                ...state,
                address:null,
            };
        
        case AT.SET_USER_ID:
        return  {
            ...state,
            userId:action.userId,
        };

        default:
            return state;
    }
}

export default authReducer;