import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";
import tw from "twin.macro";

const Header = styled.div`
  ${tw`
        w-full
        flex
        items-center
        justify-between
        rounded-t-lg
        p-5
    `}
`;

const Title = styled.div`
  ${tw`
        font-bold
        text-subtitle_size
    `}
`;

const Exit = styled.div`
  ${tw`
        text-white
        cursor-pointer
        justify-end
    `}
  svg {
    ${tw`
            w-6
            h-6
        `}
  }
`;

interface Prop {
  title: string;
  close: Function;
  headerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const DefaultBG = tw`
    bg-primary
`;
const DefaultText = tw`
    text-white
`;
function ModalHeader(prop: Prop) {
  return (
    <Header style={prop.headerStyle ? prop.headerStyle : DefaultBG}>
      <Title style={prop.textStyle ? prop.textStyle : DefaultText}>
        {prop.title}
      </Title>
      <Exit onClick={() => prop.close()}>
        <FontAwesomeIcon
          icon={faTimes}
          style={prop.textStyle ? prop.textStyle : DefaultText}
        />
      </Exit>
    </Header>
  );
}

export default ModalHeader;
