import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OpenSeaLogo from "../socials/logo/OpenSeaLogo";
import {Socials} from "../socials/SocialsData"
import styled from "styled-components";
import tw from "twin.macro";


const LinkContainer = styled.div`
    ${tw`
        flex
        hover:cursor-pointer
        h-full
    `}
`

const Link = styled.a`
    ${tw`
        text-neon_green
    `}
    
    svg{
        fill:#c6d72d;
        width:auto;
        height:25px;
    }
`

function SocialsBtn(prop:any){
    const social:Socials = prop.social;
    let icon:any;

    if (social.logo == null){
        icon = <OpenSeaLogo  />
    } else{
        icon = <FontAwesomeIcon icon={social.logo}/>;
    }

    return (
        <LinkContainer>
            <Link href={social.link} target="_blank" rel="noreferrer">
                {icon}
            </Link>
        </LinkContainer>
        
    );
}

export default SocialsBtn;