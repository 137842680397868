import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { setNotification } from "../../redux/actions/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import tw from "twin.macro";
import { Container, Background, Content } from "../modal/ModalBG";

const NotificationContent = styled(Content)`
  ${tw`
        text-white
        w-4/5
        flex
        justify-between
        mt-8
        p-5
    `}

  height:fit-content;
  &.error_msg {
    ${tw`
            bg-err_color
        `}
  }

  &.notification_msg {
    ${tw`
            bg-msg_color
        `}
  }
`;

const Message = styled.div``;

const Exit = styled.div`
  ${tw`
        text-white
        cursor-pointer
        w-6
    `}
  svg {
    ${tw`
            w-6
            h-6
        `}
  }
`;

function Notification() {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.notification);
  const action = bindActionCreators(setNotification, dispatch);

  const close = () => {
    action(false);
  };
  return (
    <Container className={state.isActive ? "active" : ""}>
      <Background onClick={close} />

      <NotificationContent
        className={state.isError ? "error_msg" : "notification_msg"}
      >
        <Message>{state.message}</Message>
        <Exit onClick={close}>
          <FontAwesomeIcon icon={faTimes} />
        </Exit>
      </NotificationContent>
    </Container>
  );
}

export default Notification;
