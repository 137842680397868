import { useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import {CoinSrc} from "../../utilities/Image/images";

const Container = styled.div`
    ${tw`
        bg-white
        rounded-xl
        max-w-md
        w-72
        h-40
        flex
        flex-col
        mt-24
        md:mt-0
    `}   
`

const Header = styled.div`
    ${tw`
        bg-primary
        rounded-t-xl
        h-16
        flex
        p-3
        items-center
        justify-center
    `}
    
`
const TextStyle = tw`
    font-title
    text-[2em]
    tracking-wider
`
const Title = styled.p`
    ${TextStyle}
    ${tw`
        text-neon_green
    `}
    
`
const GuavaCoin = styled.img`
    ${tw`
        w-12
    `}
`
const BalanceContainer = styled.div`
   ${tw` 
        w-full
        h-full
        flex
        items-center
        justify-center
        p-1
    `}
`

const CurrentBalance = styled.p`
    ${TextStyle}
    ${tw`
        text-primary
        min-w-[3rem]
        font-body
        text-center
        text-[1em]
    `}
    &.connected{
        ${tw`
            text-right
            text-[2em]
        `}
    }
`

function Balance(){
    const balanceState = useSelector((state:any) => state.balance);
    const authState = useSelector((state:any) => state.auth);
    
    return(
        <Container>
            <Header>
                <Title>MY $GUAVA BALANCE</Title>
            </Header>
            <BalanceContainer>
                <CurrentBalance data-testid={"curr-balance"} 
                    className={
                        authState.isConnected?"connected":""}>{(
                        authState.isConnected?
                        balanceState.totalBalance:
                        "Connect Wallet to View Balance")
                    }
                </CurrentBalance>
                <GuavaCoin src={CoinSrc} alt="guava icon" className={authState.isConnected? "":"hidden"}/>
            </BalanceContainer>
        </Container>
    )
}

export default Balance;