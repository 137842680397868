import axios from "axios";
import { setActivity } from "../../redux/actions/activities";
import store from "../../redux/store";
import { Activity } from "../Interface/activity";
import { SUCCESS } from "../status";

// Load the User Guava Balances
async function SetupActivities() {
  let auth = store.getState().auth;
  let url = `${process.env.REACT_APP_BACKEND_URL}/events/user/${auth.userId}/?limit=10`;

  await axios
    .get(url, { headers: { Authorization: "Bearer " + auth.token } })
    .then((res) => {
      if (res.data.status === SUCCESS) {
        let events: Activity[] = res.data.data.events;

        if (events.length < 1) {
          return;
        }
        store.dispatch(setActivity(events));
      }
    })
    .catch((err) => {
      // Error 404
      throw new Error("Trouble Getting Activity History ");
    });
}

export { SetupActivities };
