import * as Container from "../../components/containers/Container";
import { Filler } from "../filler/Filler";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import Notification from "../notification/Notification";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";


const Wrapper = styled.div`
  ${Container.Wrapper}
  ${tw`
    bg-bg_white
  `}
`

function Layout({
    children,
  }: {
    children: React.ReactNode | React.ReactNode[];
  }) {
    return (
      <Wrapper>
        <Notification/>
        <Navbar/>
        {children}
        <Filler/>
        <Footer/>
      </Wrapper>
    );
  }

export default Layout;