const option_day:Intl.DateTimeFormatOptions ={ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const options:Intl.DateTimeFormatOptions ={ year: 'numeric', month: 'long', day: 'numeric' };

function getFormattedDate(date:string):string{    
    const dateFormat = new Date(date);
    return dateFormat.toLocaleDateString("en-US", options);
}

function getFormattedDateDay(date:string):string{    
    const dateFormat = new Date(date);
    return dateFormat.toLocaleDateString("en-US", option_day);
}

function getFormattedDateShort(date:string):string{    
    const dateFormat = new Date(date);
    return dateFormat.toLocaleDateString("en-US");
}

export { getFormattedDate, getFormattedDateShort, getFormattedDateDay };