import * as AT from "./actionTypes"

const setNotification = (isActive:boolean) => ({
    type:AT.SET_NOTIFICATION,  
    isActive:isActive  
})

const setNotificationMessage = (message:string, isError:boolean) => ({
    type:AT.SET_NOTIFICATION_MESSAGE,  
    message:message,  
    isError:isError,
    
})

export { setNotification, setNotificationMessage }