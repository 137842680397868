import * as AT from "../actions/actionTypes"

let init_state = {
    isNavActive:false,
};

export default function landingReducer(state = init_state, action:any){
    switch (action.type){
        case AT.IS_NAV_ACTIVE:
            return {
                ...state,
                isNavActive:!state.isNavActive
            };
        case AT.SET_NAV_STATUS:
            return {
                ...state,
                isNavActive:!action.status
            };
        default:
            return state;
    }
}
