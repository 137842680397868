import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import  * as navActions  from "../../redux/actions/nav";

import styled from "styled-components";
import tw from "twin.macro";
import { NavLogo } from "../../utilities/Image/images";
import ConnectBtn from "../buttons/ConnectBtn";

const Border = tw`
    bg-repeat-x
    bg-bottom
    background-size[100px]
`
const Nav = styled.div`
    ${Border}
    ${tw`
        w-full
        h-32
        flex
        justify-center
        bg-transparent
        z-10
    `}
    background-image: url("/img/borders/green-header-bg-1.png");
    &.active{
        ${tw`
            bg-primary
            transition-duration[300ms]
            md:bg-transparent
            md:transition-duration[0ms]
        `} 
    }
`
const Container = styled.div`
    ${tw`
        w-full
        flex
        items-center
        justify-center
        bg-primary
        z-20
        h-24
    `}
   
`

const NavContainer = styled.div`
    ${tw`
        w-full
        max-w-5xl
        flex
        justify-between   
        pl-3
        pr-3
    `}
`

const Logo = styled.a`
    ${tw`
        h-full 
        w-full
        flex 
        items-center
        md:pl-2
    `}
`
const LogoImg = styled.img`
    ${tw`
        h-12
    `}
    
`
const MenuIcon = styled.a`
    ${tw`
        text-peach
        flex
        items-end
        hover:cursor-pointer
        md:hidden
    `}
    svg{
        ${tw`
           h-8
           w-8
        `} 
    }
`
const Menu = styled.div`
    ${tw`
        flex
        items-center
        h-24
        z-10
        md:pr-2
    `}    
`

const MenuItems = styled.ul`
    ${Border}
    ${tw`
        absolute
        top-[-250px]
        left-0
        gap-5        
        w-full
        text-center
        pb-10
        z-[1]
        md:relative
        md:flex
        md:top-0
        md:bg-none
        md:pb-0
    `}
    
    background-image: url("/img/borders/green-header-bg-1.png");
    &.active{
        ${tw`
            top-24
            transition-duration[300ms]
            md:top-0
            md:transition-duration[0ms]
        `}     
    }
`
const MenuItem = styled.li`
    ${tw`
        text-white
        text-nav_size
        font-title
        font-thin
        tracking-wider
        bg-primary
        pb-3
        md:pb-0
    `}
    .active{
        ${tw`
            text-neon_green
        `}
    }    
`


function Navbar() {
    const dispatch = useDispatch();
    const navState = useSelector((state:any) => state.nav);
    const navAction = bindActionCreators(navActions,dispatch);

    const NavItems = 
        <Menu>
            <MenuItems className={navState.isNavActive? "active":""}>
                <MenuItem><a href="https://guavagirlsnft.com/">HOME</a></MenuItem>
                <MenuItem><NavLink to="/dashboard">DASHBOARD</NavLink></MenuItem>
                <MenuItem><NavLink to="/market">MARKET</NavLink></MenuItem>
                <MenuItem><NavLink to="/faqs">FAQs</NavLink></MenuItem>
                <ConnectBtn/>
            </MenuItems>
        </Menu>

    return (
        <Nav className={navState.isNavActive? "active":""}>
            <NavContainer>
                <Container >
                    <Logo href="/dashboard"><LogoImg src={NavLogo} alt="Logo Image"/></Logo>
                    <MenuIcon onClick={navAction.toggleNav}>
                        <FontAwesomeIcon icon={navState.isNavActive? faTimes:faBars} />
                    </MenuIcon>
                </Container>
                {NavItems}
            </NavContainer>            
        </Nav>
    );
}

export default Navbar;