import * as AT from "./actionTypes"

export const setRarity = (rarity:any) => ({
    type:AT.SET_RARITY,
    rarity:rarity,
})


export const setSnapshot = (snapshot:any) => ({
    type:AT.SET_SNAPSHOT,
    snapshot:snapshot,
})


export const setTotalBalance = (balance:number) => ({
    type:AT.SET_TOTAL_BALANCE,
    totalBalance:balance,
})
