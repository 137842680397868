import tw from "twin.macro";
import styled from "styled-components";


const TableWrapper = styled.div`
    ${tw`
        bg-table_bg
        rounded-lg
        w-full
        py-3
        mt-5
        grid
        row-span-full
        overflow-x-auto
        whitespace-nowrap
    `}
`

const TableContainer = styled.table``

const TableHead = styled.thead``

const TableBody = styled.tbody``

const TablePadding=tw`
    px-5
`

const TableRow = styled.tr`
    &.dark{
        ${tw`
            bg-table_row
        `}
    }    
`

const TableHeader = styled.th`    
    ${TablePadding}
    ${tw`
        text-left
        text-orange
        text-table_th_size
        font-title
        font-light
    `}
`

const TableData = styled.td`
    ${TablePadding}
    ${tw`
        text-body_size
        font-medium  
    `}   
`

const ColWidth = tw`
    w-1/3
`

const FooterStyle = tw`
    border-t-bg_bot
    border-t-2
    w-full
`



const DataFiller = styled.tr`
    ${tw`
        h-8
    `}

    &.none{
        ${tw`
            h-0
        `}
    }
    
` 

interface Prop {
    rowHeader:string[], 
    data:JSX.Element[][], 
    rowFooter?:JSX.Element[] | null
}

function Table(prop:Prop){
    const RowHeader = 
        <TableRow >
            {prop.rowHeader.map(( rowData:string, key:number )=>{
                return (
                    <TableHeader key={key}>
                        {rowData}
                    </TableHeader>
                )
            })}
        </TableRow>  

    let dark:boolean = true;
    const Data = 
        prop.data.map((row:JSX.Element[], key:number) => {
            return (
                <TableRow className={dark ?"dark":""} key={key}>
                    {dark = !dark}
                    {row.map((element:JSX.Element, key:number) => {
                        return (
                            <TableData key={key} style={ColWidth}>{element}</TableData>
                        )
                    })}
                </TableRow>
            )
        })
        
    const RowFooter = 
        <TableRow style={FooterStyle}>
            { prop.rowFooter?.map(( foot:JSX.Element, key:number )=>{
                    return (<TableData key={key} style={ColWidth}>{foot}</TableData>)
                })
            }
        </TableRow>    

    return (
        <TableWrapper id="Table">
            <TableContainer>
                <TableHead>
                    {RowHeader}
                </TableHead>
                <TableBody>
                    {Data}
                    <DataFiller className={prop.rowFooter ? "" : "none"}/>
                    { prop.rowFooter  ? 
                        RowFooter : 
                        <></>
                    }
                </TableBody>
            </TableContainer>
        </TableWrapper>
    )
}

export { Table }