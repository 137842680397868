import { IconDefinition, faTwitter, faInstagram,faDiscord } from '@fortawesome/free-brands-svg-icons';

const link_website="https://guavagirlsnft.com/";

const link_twitter="https://twitter.com/guavagirlsnft";
const link_discord="https://discord.gg/guavagirls";
const link_instagram="https://www.instagram.com/guavagirlsnft";

const link_opensea="https://opensea.io/collection/guavagirls";
const link_wiki="wiki.guavagirlsnft.com";
const link_twitter_recordings="https://guavagirlsnft.notion.site/9930e1b8813a47e99c75494cdb301c5c?v=901443a0768940cd8c73c638fba9ec65";


interface Socials{
    name:string;
    link:string;
    logo?:IconDefinition;
    logo_path?:string;
}

const Twitter:Socials={
    name: "Twitter",
    link: link_twitter,
    logo: faTwitter
}

const Discord:Socials={
    name: "Discord",
    link: link_discord,
    logo: faDiscord
}

const Instagram:Socials={
    name: "Instagram",
    link: link_instagram,
    logo: faInstagram
}

const Opensea:Socials={
    name: "Opensea",
    link: link_opensea,
    logo_path: "./img/logo/opensea-logo.svg"
}

export { link_website, link_twitter, link_discord, link_instagram, link_opensea, link_wiki, link_twitter_recordings, Twitter, Discord, Instagram, Opensea };
export type { Socials };
