import React from "react";
import styled from "styled-components";
import tw, { TwStyle } from "twin.macro";
import { ProductType } from "../../utilities/Interface/product";

const Wrapper = styled.div`
  ${tw`
        w-full
        p-card-pad
        flex
        justify-center
        items-center
        text-white
        rounded-t-card
    `}
`;

interface Prop {
  type: ProductType;
  headerStyle: TwStyle;
}

function ProductTypeLabel(prop: Prop) {
  const headerStyle = prop.headerStyle;
  const name = prop.type.name;

  return <Wrapper style={headerStyle}>{name}</Wrapper>;
}

export default ProductTypeLabel;
