import * as AT from "../actions/actionTypes"

let init_state = {
    isError:false,
    isActive:false,
    message:"",
};

export default function notificationReducer(state = init_state, action:any){
    switch (action.type){
        case AT.SET_NOTIFICATION:
            return {
                isActive:action.isActive
            };

        case AT.SET_NOTIFICATION_MESSAGE:
            return  {
                ...state,
                message:action.message,
                isError:action.isError,
                isActive:true  
            };
            
        default:
            return state;
    }
}
