import React from "react";
import { Filler } from "../../components/filler/Filler";
import Layout from "../../components/layout/Layout";
import tw from "twin.macro";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const MessageContainer = styled.div`
  ${tw`
    font-title
    text-center
    text-primary
    flex
    flex-col
    gap-2
  `}
`

const Title = styled.div`
  ${tw`
    text-message_med
    md:text-message_lrg
  `}
`

const Message = styled.div`
  ${tw`
      text-message_sml
      md:text-message_med
  `}
`

const Link = styled.a`
  ${tw`
    underline
    text-secondary
    cursor-pointer

  `}
`

const Icon = styled.img`
  ${tw`
    w-32
    self-center
    md:w-52
  `} 
`

function PageNotFound(){
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    navigate("/dashboard");
  }
  
  return(
      <Layout>
          <Filler/>
          <MessageContainer>
            <Icon src="./img/logo/Guava_Girls-3.png"></Icon>
            <Title>Error 404: Page Not Found</Title>
            <Message>Return to <Link onClick={routeChange}>Dashboard</Link></Message>
          </MessageContainer>
          <Filler/>
      </Layout>
  );
}

export default PageNotFound;