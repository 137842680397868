import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${tw`
        fixed
        top-0
        left-0
        w-full
        h-screen
        flex
        justify-center          
        invisible
        z-30
    `}
    
    &.active{
        ${tw`
            visible 
        `}
    }
`

const Background = styled.div`
    ${tw`
        absolute
        top-0
        left-0
        w-screen
        h-screen 
        z-30
        bg-popup_background
    `}
`

const Content = styled.div`
    ${tw`
        rounded-lg
        shadow-2xl
        z-30
        w-full
        max-w-2xl
    `}
`

export { Container, Background,Content };