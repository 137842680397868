import * as BalanceActions from "../../redux/actions/balance";
import { SnapshotDefault } from "../Interface/snapshot";
import store from "../../redux/store";
import { SUCCESS } from "../status";
import axios from "axios";

// Load the Guava Girl Rarities
export async function setupRarity() {
  let url = `${process.env.REACT_APP_BACKEND_URL}/static-pages/calculation-details`;

  await axios
    .get(url)
    .then(setRarity)
    .catch((err) => {
      // Error 404
      throw new Error("Trouble Getting Rarity Values");
    });
}

// Set Rarity to the Redux Store
const setRarity = (response: any) => {
  if (response.data.status === SUCCESS) {
    let data = response.data.data;
    let snapshot = SnapshotDefault;

    if (data) {
      snapshot.classic.value = data.baseEarnings;
      snapshot.fruity.value = data.fruityEarnings;
      snapshot.legendary.value = data.legendaryEarnings;
    }

    // Set the rarity array
    store.dispatch(BalanceActions.setSnapshot(snapshot));
  }
};
