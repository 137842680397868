import {Twitter, Instagram, Discord, Opensea} from "../socials/SocialsData"
import SocialsBtn from "../buttons/SocialsBtn"
import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
    ${tw`
        flex
        gap-2
        items-center
        h-auto
        pt-2
    `}
`

function SocialsContainer() {   
    return (
        <Container>
            <SocialsBtn social={Instagram}/>
            <SocialsBtn social={Twitter}/>
            <SocialsBtn social={Discord}/>
            <SocialsBtn social={Opensea}/>
        </Container>

    )    
}

export default SocialsContainer;