import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { CoinSrc } from "../../utilities/Image/images";
import { Product } from "../../utilities/Interface/product";
import { Filler } from "../filler/Filler";
import { Title, SubTitle } from "../text/Text";
import UserInput from "./UserInput";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Notification from "../notification/Notification";
import parse from "html-react-parser";

const Wrapper = styled.div`
  ${tw`
        text-primary
        w-full
        grid
        gap-5
        sm:grid-cols-2
    `}
  height: fit-content;
`;
const NoImageContainer = styled.div`
  ${tw`
      w-full
      h-full
      bg-product_img_bg
      flex
      items-center
      justify-center
    `}
`;

const ProductImage = styled.img``;
const LeftDetail = styled.div`
  ${tw`
      w-full
      h-full
  `}
`;

const RightDetail = styled.div`
  ${tw`
        w-full
        h-full
    `}
`;

const SoldOut = styled.span`
  ${tw`
    text-err_color
  `}
`;

const CostContainer = styled.div``;

const Cost = styled.div`
  ${tw`
        flex
        items-center
        text-card_size
        font-bold
    `}
  width:fit-content;
  &.curr-balance {
    ${tw`
      text-orange
    `}
  }
`;
const CoinImage = styled.img`
  ${tw`
        w-10
        h-10
    `}
  &.curr-balance {
    ${tw`
            w-6
            h-6
        `}
  }
`;
const Border = styled.div`
  ${tw`
        border-gray-300
        border-b-[2px]
        mb-7
        mt-3
    `}
`;
const LearnMore = styled.a`
  ${tw`
    text-orange
    underline
  `}
`;

interface Prop {
  product: Product;
}

function ProductInfo(prop: Prop) {
  const balance = useSelector((state: any) => state.balance);
  const auth = useSelector((state: any) => state.auth);

  const title = prop.product.title;
  const description = prop.product.description;
  const price = prop.product.price;
  const productId = prop.product.id;
  const img = prop.product.images.length > 0 ? [...prop.product.images] : [];
  const soldOut = prop.product.soldOut;
  const learnMoreLink = prop.product.learnMoreLink;

  return (
    <>
      <Notification />
      <Wrapper>
        <LeftDetail>
          {img.length > 0 ? (
            <Carousel showThumbs={false}>
              {img.map((url: string, key: number) => {
                return (
                  <div key={key}>
                    <ProductImage src={url} alt={`$image_${productId}`} />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <NoImageContainer>
              <ProductImage alt="No Product Image" />
            </NoImageContainer>
          )}
        </LeftDetail>

        <RightDetail>
          <Title>
            {soldOut ? <SoldOut>[SOLD OUT] </SoldOut> : <></>}
            {title}
          </Title>
          <SubTitle>{parse(description)}</SubTitle>
          {learnMoreLink ? (
            <LearnMore href={learnMoreLink} target="_blank">
              Learn More
            </LearnMore>
          ) : (
            <></>
          )}

          <Border />

          <CostContainer>
            <Cost>
              Item Price: {price}
              <CoinImage src={CoinSrc} />
            </Cost>
            <Filler />
            {auth.isConnected ? (
              <Cost className="curr-balance">
                My Balance: {balance.totalBalance}
                <CoinImage src={CoinSrc} />
              </Cost>
            ) : (
              <></>
            )}
          </CostContainer>
          <Border />
          <UserInput balance={balance.totalBalance} product={prop.product} />
        </RightDetail>
      </Wrapper>
    </>
  );
}

export default ProductInfo;
