import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import tw from "twin.macro";
import { setIsLoading } from "../../redux/actions/market";
import { setNotificationMessage } from "../../redux/actions/notification";
import store from "../../redux/store";
import { purchaseProduct } from "../../utilities/API/market";
import { Product } from "../../utilities/Interface/product";
import Modal from "../modal/Modal";
import PurchaseButton from "./PurchaseButton";

const Wrapper = styled.div`
  ${tw`
        flex
        flex-col
        items-center
    `}
`;

const Form = styled.form`
  ${tw`
       flex
       flex-col
       w-full
    `}
`;

const inputStyle = tw`
    bg-gray-300
    w-full
    mb-3
    px-3
    py-3
`;

const NameInput = styled.input`
  ${inputStyle}
  ${tw`
    `}
`;

const EmailInput = styled.input`
  ${inputStyle}
`;

const ButtonContainer = styled.div`
  ${tw`
    w-full
    flex
    justify-end
  `}
`;
const Button = styled.button`
  ${tw`
    border-2
    border-peach
    text-peach
    font-bold
    p-3
    rounded
  `}
  &:hover {
    ${tw`
      bg-peach
      text-primary
    `}
  }
`;

interface Prop {
  balance: number;
  product: Product;
}

function UserInput(prop: Prop) {
  const dispatch = useDispatch();
  const loadingAction = bindActionCreators(setIsLoading, dispatch);
  const [toggle, setToggle] = useState(false);

  const nameID = `fullname_${prop.product.id}`;
  const emailID = `email_${prop.product.id}`;
  const purchaseType = prop.product.purchaseTypes || {
    id: 1,
    name: "Purchase",
  };

  const handleClick = () => {
    toggleHandler();
    let fullname_input = (document.getElementById(nameID) as HTMLInputElement)
      .value;
    let email_input = (document.getElementById(emailID) as HTMLInputElement)
      .value;

    if (fullname_input.length > 0 && email_input.length > 0) {
      switch (purchaseType.id) {
        case 1: //Purchase
          if (prop.product.price > prop.balance) {
            store.dispatch(
              setNotificationMessage("Insufficient Guava Balance.", true)
            );
            break;
          } else {
            loadingAction(true);
            purchaseProduct(prop.product.id, fullname_input, email_input)
              .then(() => {
                store.dispatch(
                  setNotificationMessage(
                    `Successfully Purchased ${prop.product.title}`,
                    false
                  )
                );
                loadingAction(false);
              })
              .catch((err: Error) => {
                store.dispatch(setNotificationMessage(err.message, true));
                loadingAction(false);
              });
          }
          break;

        case 2: // Raffle
          console.log("Raffle for", fullname_input, email_input);
          break;
        case 3: // Auction
          console.log("Auction", fullname_input, email_input);
          break;
        default:
          console.log("Unknown Purchase Type");
      }
    }
  };

  const toggleHandler = () => {
    setToggle(!toggle);
  };

  return (
    <Wrapper>
      <Modal
        state={toggle}
        close={toggleHandler}
        title={"Please confirm to purchase"}
        info={
          <ButtonContainer>
            <Button onClick={handleClick}>Confirm</Button>
          </ButtonContainer>
        }
      />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          toggleHandler();
        }}
      >
        <NameInput
          id={nameID}
          placeholder="Full Name *"
          type="text"
          required
        ></NameInput>
        <EmailInput
          id={emailID}
          placeholder="Email *"
          type="email"
          required
        ></EmailInput>
        <PurchaseButton
          purchaseType={purchaseType}
          click={() => {}}
          isSoldOut={prop.product.soldOut}
        />
      </Form>
    </Wrapper>
  );
}

export default UserInput;
