import styled from "styled-components";
import tw from "twin.macro";
import Balance from "./Balance";
import {BannerSrc} from "../../utilities/Image/images";

const Container = styled.div`
    ${tw`
        w-full 
        flex
        flex-col
        items-center
        justify-center
        mt-[-30px]
        z-0
    `}
`
const BalanceContainer = styled.div` 
    ${tw`
        absolute
        z-10 
        flex
        flex-row
        items-center
        gap-6
        top-32
        md:pt-12
        md:top-28
    `}  
`

const GGContainer = styled.div`
    ${tw`
        max-w-md
        w-full
        absolute
        top-6
        left-0
        z-[-1]
        md:static
    `}
`

const GGImg = styled.img`
    ${tw`
        w-full
        h-full
    `}
`

const TopBanner = styled.div`
    ${tw`
        bg-bg_top
        w-full
        h-72
        md:h-96
    `}
`

const BotBanner = styled.div`
    ${tw`
        bg-bg_bot
        w-full
        h-20
        md:h-36
    `}
    
`

function Banner(){
    return(
        <Container >
            <BalanceContainer>
                <GGContainer>
                    <GGImg src={BannerSrc} alt="guava girl illustration"/>
                </GGContainer>
                <Balance/>
            </BalanceContainer>
            <TopBanner/>
            <BotBanner/>
        </Container>
    )
}

export default Banner;