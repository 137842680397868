import { SnapshotDefault } from "../../utilities/Interface/snapshot";
import * as AT from "../actions/actionTypes"

let init_state = {
    totalBalance:0,
    snapshot:SnapshotDefault,
};

export default function landingReducer(state = init_state, action:any){
    switch (action.type){
        case AT.SET_RARITY:
            return {
                ...state,
                rarity:action.rarity
            };

        case AT.SET_SNAPSHOT:
            return {
                ...state,
                snapshot:action.snapshot
            };

        case AT.SET_TOTAL_BALANCE:
            return {
                ...state,
                totalBalance:action.totalBalance
            };

        default:
            return state;
    }
}
