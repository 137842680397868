import React, { useEffect, useState } from "react";
import * as Container from "../../components/containers/Container";
import tw from "twin.macro";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  defaultProductType,
  Product,
  ProductType,
} from "../../utilities/Interface/product";
import ProductInfo from "../../components/product/ProductInfo";
import ModalHeader from "../../components/modal/ModalHeader";
import { getHeaderBG, getHeaderText } from "../../components/product/getColor";
import { getProduct } from "../../utilities/API/market";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { setNotificationMessage } from "../../redux/actions/notification";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  ${Container.Wrapper}
  ${tw`
        h-full
    `}
`;

const ProductContainer = styled.div`
  ${Container.Content}
  ${tw`
        bg-white
        h-full
        min-h-screen
        shadow-2xl
        md:px-0
    `}
`;

const Content = styled.div`
  ${Container.Content}
  ${tw`
    p-5
  `}
`;

const NoProduct = styled.div`
  ${Container.Content}
  ${tw`
        text-center
    `}
`;

const Loader = styled.div`
  ${tw`
    w-full
    h-screen
    flex
    justify-center
    items-center
  `}
`;

function ProductsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const productID: number = Number(params.id);

  const productState = useSelector((state: any) => state.market);
  const product: Product = productState.curr_product;

  useEffect(() => {
    const callProduct = async () => {
      await getProduct(productID)
        .then(() => setIsLoading(false))
        .catch((err: Error) => {
          store.dispatch(setNotificationMessage(err.message, true));
        });
    };
    callProduct();
  }, [productID]);

  let productType: ProductType =
    product && product.productTypes.length > 0
      ? product.productTypes[0].productType
      : defaultProductType.productType;

  const HeaderStyle = {
    ...getHeaderBG(productType.id),
    ...tw`rounded-none`,
  };

  const handleReturn = () => {
    window.location.href = "/market";
  };

  return (
    <Wrapper>
      <ProductContainer>
        {isLoading ? (
          <Loader>
            <FontAwesomeIcon icon={faSpinner} spin />
          </Loader>
        ) : product ? (
          <>
            <ModalHeader
              title={productType.name}
              close={handleReturn}
              headerStyle={HeaderStyle}
              textStyle={getHeaderText(productType.id)}
            />
            <Content>
              <ProductInfo product={product} />
            </Content>
          </>
        ) : (
          <>
            <ModalHeader
              title={"No Product"}
              close={handleReturn}
              headerStyle={HeaderStyle}
            />
            <NoProduct>Product not available</NoProduct>
          </>
        )}
      </ProductContainer>
    </Wrapper>
  );
}

export default ProductsPage;
