import * as AT from "./actionTypes"

export const setConnected = (connected:boolean) => ({
    type:AT.SET_CONNECTED,
    isConnected:connected
})

export const toggleConnected = () => ({
    type:AT.TOGGLE_CONNECTED,
})

export const setToken = (token:string|null) => ({
    type:AT.SET_TOKEN,
    token: token,
})

export const delToken = () => ({
    type:AT.DEL_TOKEN,
})

export const setAddress = (address:string|null) => ({
    type:AT.SET_ADDRESS,
    address: address,
})

export const delAddress = () => ({
    type:AT.DEL_ADDRESS,
})

export const setUserId = (userId:number|null) => ({
    type:AT.SET_USER_ID,
    userId: userId,
})

export const delUserId = () => ({
    type:AT.DEL_USER_ID,
})
