import styled from "styled-components";
import tw from "twin.macro";
import { useSelector } from "react-redux";
import AuthenticateUser from "../../utilities/Auth/AuthenticateUser";

const Button = styled.button`
  ${tw`
        font-title
        text-nav_size
        text-white

        pr-5
        pl-5
        border-2
        rounded-xl

        border-peach
    `}
`;

interface Prop {
  style?: React.CSSProperties;
}

function ConnectBtn(prop: Prop) {
  const authState = useSelector((state: any) => state.auth);
  const Auth = new AuthenticateUser();

  return authState.isConnected && authState.address != null ? (
    <Button>{authState.address.substring(0, 5) + "..."}</Button>
  ) : (
    <Button
      style={prop.style ? prop.style : undefined}
      onClick={() => {
        Auth.onConnect();
      }}
    >
      {"CONNECT"}
    </Button>
  );
}

export default ConnectBtn;
