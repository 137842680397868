import * as AT from "./actionTypes"


export const toggleNav = () => ({
    type:AT.IS_NAV_ACTIVE,
})

export const setNavstatus = (status:boolean) => ({
    type:AT.SET_NAV_STATUS,
    status:status
})
