import Web3 from 'web3';
import { setUser, clearUser, clearStorageData } from "./HandleUserData"
import { setupAccount } from "../initSetup";

async function AuthInit() {
      //  Check if Wallet is Connected
      async function walletConnected() {
        let web3: any;
        let accounts = "";

        if ((window as any).ethereum) {
          web3 = new Web3((window as any).ethereum);
        } 

         // Check if wallet's connected
        if (web3){
            accounts = await web3.eth.getAccounts();   
        }
        
        return accounts;
      }

      walletConnected().then((accounts:string)=>{

        //  Is connected if account number received
        if (accounts.length > 0){      
          // Check Token
          const token = JSON.parse(localStorage.getItem('token') || 'null'); 
          const userId = JSON.parse(localStorage.getItem('userId') || 'null'); 
          
          if (token != null && userId != null) {
            setUser(accounts[0], token, userId)
            setupAccount();
            return;
          } 
        }
        clearUser();
        clearStorageData();
      });
}


export default AuthInit;