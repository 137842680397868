function formatNumberAbr(num:number):string{
    const thou = 1000;
    const mill = 1000000;
    const bill = 1000000000;

    if (num < thou){
        return (num.toString());
    }else if(num > thou && num < mill){
        return (num/thou+"K");
    }else if(num > mill && num < bill){
        return (num/mill+"M");
    }else{
        return (num/bill+"B");
    }
}

function formatNumberComma(num:number):string{
    return num.toLocaleString("en-US")
}

export {formatNumberAbr, formatNumberComma};