import React from "react";
import { useSelector } from "react-redux";
import { PurchaseType } from "../../utilities/Interface/product";
import ConnectBtn from "../buttons/ConnectBtn";
import styled from "styled-components";
import tw, { TwStyle } from "twin.macro";

const ButtonStyle = tw`
    text-white
    border-0
    border-radius[5px]
    font-bold
    text-nav_size
    px-7
    py-3
    mt-3

`;

const Button = styled.button`
  ${ButtonStyle}
  width:fit-content;
`;

const ConnectStyle = tw`
    bg-purchase_connect
    text-white
    border-0
    border-radius[5px]
    font-bold
    text-nav_size
    px-7
    py-3
    mt-3
`;

interface Prop {
  purchaseType: PurchaseType;
  click: Function;
  isSoldOut: boolean;
}

function PurchaseButton(prop: Prop) {
  const authState = useSelector((state: any) => state.auth);
  const isLoading = useSelector((state: any) => state.market);

  const id = prop.purchaseType.id;
  return authState.isConnected ? (
    !prop.isSoldOut ? (
      <Button
        style={getColor(id)}
        onClick={() => {
          prop.click();
        }}
      >
        {isLoading.isLoading ? "Loading..." : getText(id)}
      </Button>
    ) : (
      <></>
    )
  ) : (
    <ConnectBtn style={ConnectStyle} />
  );
}

function getColor(typeID: number): TwStyle {
  switch (typeID) {
    case 1:
      return tw`bg-purchase_buy`;
    case 2:
      return tw`bg-purchase_raffle`;
    case 3:
      return tw`bg-purchase_auction`;
    default:
      return tw`bg-purchase_buy`;
  }
}

function getText(typeID: number): string {
  switch (typeID) {
    case 1:
      return "Purchase Now";
    case 2:
      return "Enter Raffle";
    case 3:
      return "Enter Bid ";
    default:
      return "Purchase Now";
  }
}

export default PurchaseButton;
