import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import HTMLReactParser from 'html-react-parser';    
import tw from 'twin.macro';
import React from 'react';


interface Prop{
  title:string,
  description:string,
  index:number,
  header:{
    bg:string,
    text:string
  },
  body:{
    bg:string,
    text:string
  },
}


const Title = tw`
  font-bold
`

const Body = tw``

function AccordionTemplate(prop:Prop) {    
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon style={{fill: prop.header.text}}/>}
        aria-controls="panel1a-content" 
        sx={{
          backgroundColor: `${prop.header.bg}`,
        }}
      >
        <Typography 
          style={Title} 
          color={prop.header.text}
        >
          {prop.title}
        </Typography>
      </AccordionSummary>
    
      <AccordionDetails
        sx={{
          backgroundColor: `${prop.body.bg}`
        }}
      >
      <Typography style={Body} color={prop.body.text}>
        {HTMLReactParser(prop.description)}
      </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionTemplate;
  