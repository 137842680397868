import { Body, Title as OG_Title } from "../../components/text/Text";
import * as Container from "../../components/containers/Container";
import { Space } from "../../components/filler/Filler";
import {Faq, Faqs} from "./faqs"
import AccordionTemplate from "../../components/accordion/AccordionTemplate";
import Layout from "../../components/layout/Layout";
import styled from "styled-components";
import tw from "twin.macro";

const AccordionContainer = styled.div``

const Section = styled.div`
  ${Container.Section}
`

const Content = styled.div`
  ${Container.Content}
`

const Title = styled(OG_Title)`
    ${tw`
        text-primary
    `}
`

function FAQs() {
    const primary_color = "#244a3d";
    const secondary_color = "#ffffff";

    return (
        <Layout>
            <Section>
                <Content>
                    <Title >FREQUENTLY ASKED QUESTIONS</Title>
                    <Body></Body>
                    <Space/>
                    <AccordionContainer >
                        {
                            Faqs.map((item:Faq, index:number) =>{
                                return <AccordionTemplate
                                    key={index} 
                                    index={index}
                                    title={item.title}
                                    description={item.desc}
                                    header={{ bg:`${primary_color}`, text:`${secondary_color}`}}
                                    body={{bg:`${secondary_color}`, text:`${primary_color}`}}
                                />
                            })
                        }
                    </AccordionContainer>
                </Content>
            </Section>
         </Layout>
           
    );
}

export default FAQs;