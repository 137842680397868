import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Product } from "../../utilities/Interface/product";
import ProductCard from "./Card";
import { getHeaderBG, getHeaderText } from "./getColor";

const GridWrapper = styled.div`
  ${tw`
        grid
        gap-5
        place-items-center
        grid-cols-1
        sm:grid-cols-2
        lg:grid-cols-3 
  `}
`;

interface Prop {
  products: Product[];
}

function ProductGrid(prop: Prop) {
  return (
    <GridWrapper>
      {prop.products.map((product: Product, index: number) => {
        const id =
          product.productTypes.length > 0
            ? product.productTypes[0].productType.id
            : -1;

        if (product.available) {
          const headerBG = {
            ...getHeaderBG(id),
            ...getHeaderText(id),
          };
          return (
            <ProductCard
              key={index}
              product={product}
              index={index}
              headerBg={headerBG}
            />
          );
        } else {
          return <></>;
        }
      })}
    </GridWrapper>
  );
}

export default ProductGrid;
