import  * as AuthAction  from "../../redux/actions/auth";

import store from "../../redux/store";

function setUser(address:string, token:string, userId:number){
    store.dispatch(AuthAction.setAddress(address));
    store.dispatch(AuthAction.setToken(token));
    store.dispatch(AuthAction.setUserId(userId));
    store.dispatch(AuthAction.setConnected(true));
}

function clearUser(){
    store.dispatch(AuthAction.delAddress());
    store.dispatch(AuthAction.delToken());
    store.dispatch(AuthAction.delUserId());
    store.dispatch(AuthAction.setConnected(false));
}

function setStorageData(token:string, userId:number){
    window.localStorage.setItem('token', JSON.stringify(token)); 
    window.localStorage.setItem('userId', JSON.stringify(userId))
}

function clearStorageData(){
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userId');
}

export { setUser, clearUser, setStorageData, clearStorageData } 