// NAV ACTIONS
export const IS_NAV_ACTIVE = "IS_NAV_ACTIVE";
export const SET_NAV_STATUS = "SET_NAV_STATUS";

// POPUP
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE";

export const SET_MODAL = "SET_MODAL";


// BALANCE
export const SET_RARITY = "SET_RARITY";
export const SET_SNAPSHOT = "SET_SNAPSHOT";
export const SET_SNAPSHOT_ARR = "SET_SNAPSHOT_ARR";
export const SET_TOTAL_BALANCE = "SET_TOTAL_BALANCE";


// LOGIN ACTIONS
export const SET_CONNECTED = "SET_CONNECTED";
export const TOGGLE_CONNECTED = "TOGGLE_CONNECTED";
export const SET_TOKEN = "SET_TOKEN";
export const DEL_TOKEN = "DEL_TOKEN";
export const SET_ADDRESS = "SET_ADDRESS";
export const DEL_ADDRESS = "DEL_ADDRESS";
export const SET_USER_ID = "SET_USER_ID";
export const DEL_USER_ID = "DEL_USER_ID";


// ACTIVITIES
export const ADD_ACTIVITIES = "ADD_ACTIVITIES";

// MARKET ACTIONS
export const ADD_PRODUCTS = "SET_PRODUCTS";
export const TOGGLE_PRODUCT_MODAL = "TOGGLE_PRODUCT_MODAL";
export const SET_CURR_PRODUCT = "SET_CURR_PRODUCT";
export const SET_IS_LOADING = "SET_IS_LOADING";
