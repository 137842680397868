import tw from "twin.macro";
import styled from "styled-components";

const Title = styled.h1`
    ${tw`
        font-title
        text-title_size
    `}
`

const SubTitle = styled.h2`
    ${tw`
        w-full
        font-body
        text-subtitle_size
    `}
`

const Body = styled.p`
    ${tw`
        w-full
        font-body
    `}
`

const Note = styled.p`
    ${tw`
        w-full
        italic
        font-body
    `}
`

export { Title, SubTitle, Body, Note }