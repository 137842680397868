import { Body, Note, Title } from "../../components/text/Text";
import { useSelector } from "react-redux";
import ActivityTable from "../../components/activities/ActivityTable";
import { Space } from "../../components/filler/Filler";
import {Activities as Activity} from "../../utilities/Interface/activity";

function Activities() {
    const activityState:Activity = useSelector((state:any)=>state.activities);
    let Header:string[] = ["Date", "Actvity", "$GUAVA"];


    return (
        <div data-testid="activities">
            <Title>Activity Earnings</Title>
            <Body>Your 10 most recent $GUAVA earnings.</Body>
            <ActivityTable 
                header={Header}
                data={activityState.activities}
            />
            <Space/>
            <Note><b>Note:</b> The table will only display the 10 most recent activities.</Note>
        </div>
    );
}
export default Activities;