import { combineReducers } from 'redux'
import navReducer from './nav'
import authReducer from './auth'
import notificationReducer from './notification'
import modalReducer from './modal'
import balanceReducer from './balance'
import activityReducer from './activities'
import marketReducers from './market'

const allReducers = combineReducers({
    nav:navReducer,
    auth:authReducer,
    notification:notificationReducer,
    balance:balanceReducer,
    modal:modalReducer,
    activities:activityReducer,
    market:marketReducers,
});

export default allReducers;