import styled from "styled-components";
import tw, { TwStyle } from "twin.macro";
import {
  defaultProductType,
  Product,
} from "../../utilities/Interface/product";
import ProductTypeLabel from "./ProductType";
import { Filler } from "../filler/Filler";
import { CoinSrc } from "../../utilities/Image/images";
import { formatNumberComma } from "../../utilities/Helper/formatNum";

const Wrapper = styled.div`
  ${tw`
        bg-white
        text-primary
        rounded-card
        cursor-pointer
        font-bold
        shadow-lg
        w-full
        h-card-h
        md:w-card-w
        hover:shadow-2xl

    `}

  &:hover {
    .prod_image {
      ${tw`
                scale-150
            `}
    }
  }
`;

const ImageContainer = styled.div`
  ${tw`
        bg-product_img_bg
        flex
        justify-center
        items-center
        text-center
        overflow-auto
        w-full
        h-card-img-h
    `}
`;

const Image = styled.img`
  ${tw`
        h-full
    `}
`;

const Details = styled.div`
  ${tw`
        p-card-pad
        text-card_body
        h-card-detail-h
        flex
        flex-col
    `}
`;

const Title = styled.div`
  ${tw`
        text-card_title
        mb-2
    `}
`;

const SoldOut = styled.span`
  ${tw`
    text-err_color
  `}
`;

const CostContainer = styled.div`
  ${tw`
        bg-primary
        rounded-cost
        w-full
        left-0
        px-3
        py-1
        flex
        items-center
    `}
  width:fit-content;
`;

const Cost = styled.div`
  ${tw`
       text-white
    `}
`;

const Coin = styled.img`
  ${tw`
        w-6
        h-6
    `}
`;

interface Prop {
  product: Product;
  index: number;
  headerBg: TwStyle;
}

function ProductCard(prop: Prop) {
  const product = prop.product;
  const headerBg = prop.headerBg;
  const productTypes =
    prop.product.productTypes.length > 0
      ? product.productTypes[0]
      : defaultProductType;
  const productType = productTypes.productType;
  const productImage = product.images.length > 0 ? product.images : [];

  const goToProduct = () => {
    window.location.href = `/market/${product.id}`;
  };

  return (
    <Wrapper onClick={goToProduct}>
      <ProductTypeLabel type={productType} headerStyle={headerBg} />
      <ImageContainer>
        {productImage.length > 0 ? (
          <Image
            className={"prod_image"}
            src={productImage[0]}
            alt="Product Image"
          />
        ) : (
          <>No Product Image</>
        )}
      </ImageContainer>

      <Details>
        <Title>
          {product.soldOut ? <SoldOut>[SOLD OUT] </SoldOut> : <></>}
          {product.title}
        </Title>
        <Filler />
        <CostContainer>
          <Cost>{formatNumberComma(product.price)}</Cost>
          <Coin src={CoinSrc} />
        </CostContainer>
      </Details>
    </Wrapper>
  );
}

export default ProductCard;
