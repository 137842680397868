import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import PageNotFound from "./pages/error/PageNotFound";
import FAQs from "./pages/faqs";
import Market from "./pages/market";
import ProductsPage from "./pages/market/ProductPage";
import { initSetup } from "./utilities/initSetup";

function App() {
  useEffect(() => {
    initSetup();
  }, []);
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path={"/"} element={<Navigate to="/dashboard" />} />
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/market"} element={<Market />} />
          <Route path={"/market/:id"} element={<ProductsPage />} />

          <Route path={"/faqs"} element={<FAQs />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
